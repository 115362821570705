import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useSWR, { useSWRConfig } from "swr";
import { useApi } from "../hooks/useApi";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState();
  const navigate = useNavigate();
  const apiClient = useApi();

  const controllers = window.router.controllers;
  const controller = controllers.SessionController;
  const { url } = controller.loggedUser();

  const { data, error } = useSWR(url, apiClient);
  const { cache } = useSWRConfig();

  useEffect(() => {
    if (data) {
      setUser(data);
    }

    if (error) {
      setUser(undefined);
    }
  }, [data, error]);
  
  const [loginURL] = useState(controller.initiateLogin()?.absoluteURL());

  const login = useCallback(
    async function authenticateUser(id_token, access_token) {
      const { url } = controller.authenticateUser();

      try {
        const user = await apiClient(url, {
          method: "POST",
          params: { id_token, access_token },
        });

        if (user?.error) {
          return Promise.reject(user.error);
        }

        setUser(user);

        navigate("/", { replace: true });

        return Promise.resolve(user);
      } catch (ex) {
        setUser(undefined);

        return Promise.reject(ex);
      }
    },
    [setUser, navigate, apiClient, controller]
  );

  const logout = useCallback(
    async function logout() {
      // eslint-disable-next-line no-undef
      const { url } = controller.logout();

      await apiClient(url, { method: "POST" });
      setUser(undefined);
      cache.clear();
      navigate("/", { replace: true });
      window.location.reload();
    },
    [setUser, navigate, apiClient, cache, controller]
  );

  const premiumUser = user?.role?.name === "premium";
  const premiumCreator = user?.role?.name === "premium-creator";
  const premiumAccess = premiumUser || premiumCreator;

  const value = {
    user,
    premiumAccess,
    premiumUser,
    premiumCreator,
    login,
    loginURL,
    logout,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
