import React, { Suspense, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./auth";
import { AssessmentBreadcrumb } from "./components/Breadcrumbs/AssessmentBreadcrumb";
import { AssessmentQuestionBreadcrumb } from "./components/Breadcrumbs/AssessmentQuestionBreadcrumb";
import { ExerciseBreadcrumb } from "./components/Breadcrumbs/ExerciseBreadcrumb";
import { Message } from "./i18n";
import { AppLayout } from "./layout/AppLayout";

const Loading = (props) => {
  if (props.error) {
    return (
      <div>
        Error! <button onClick={props.retry}>Retry</button>
      </div>
    );
  }

  if (props.pastDelay) {
    return <div>Loading...</div>;
  }

  return null;
};

const NavigateToLogin = () => {
  const { loginURL } = useAuth();

  useEffect(() => {
    window.location = loginURL;
  }, [loginURL]);

  return null;
};

const AuthRedirect = React.lazy(() => import("./pages/AuthRedirect"));
const ExerciseList = React.lazy(() => import("./pages/ExerciseList"));
const AssessmentList = React.lazy(() => import("./pages/AssessmentList"));
const AssessmentDetail = React.lazy(() => import("./pages/AssessmentDetail"));
const AssessmentResolution = React.lazy(() =>
  import("./pages/AssessmentResolution")
);
const AssessmentResult = React.lazy(() => import("./pages/AssessmentResult"));
const AssessmentQuestionResult = React.lazy(() =>
  import("./pages/AssessmentQuestionResult")
);
const AssessmentQuestionResolution = React.lazy(() =>
  import("./pages/AssessmentQuestionResolution")
);
const ExerciseResolution = React.lazy(() =>
  import("./pages/ExerciseResolution")
);
const ExerciseResult = React.lazy(() => import("./pages/ExerciseResult"));
const NotFound = React.lazy(() => import("./pages/NotFound"));

export const routes = [
  {
    path: "/",
    breadcrumb: () => <i className="fa fa-home" aria-hidden="true"></i>,
    element: <AppLayout />,
    children: [
      { index: true, element: <Navigate to="/exercises/" replace /> },
      {
        path: "/login",
        element: (
          <Suspense fallback={<Loading />} children={<NavigateToLogin />} />
        ),
      },
      {
        path: "/connect/cognito/redirect",
        element: (
          <Suspense fallback={<Loading />} children={<AuthRedirect />} />
        ),
      },
      {
        path: "assessments",
        breadcrumb: Message("breadcrumbs.assessments"),
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<Loading />} children={<AssessmentList />} />
            ),
          },
          {
            path: ":slug/results",
            breadcrumb: Message("breadcrumbs.assessments.results"),
            element: (
              <Suspense
                fallback={<Loading />}
                children={<AssessmentResult />}
              />
            ),
          },
          {
            path: ":slug/resolve",
            element: (
              <Suspense
                fallback={<Loading />}
                children={<AssessmentResolution />}
              />
            ),
          },
          {
            path: ":slug/question",
            breadcrumb: null, //Message("breadcrumbs.assessments.question"),
            children: [
              {
                path: ":questionSlug/results",
                breadcrumb: Message("breadcrumbs.assessments.question.results"),
                element: (
                  <Suspense
                    fallback={<Loading />}
                    children={<AssessmentQuestionResult />}
                  />
                ),
              },
              {
                path: ":questionSlug",
                breadcrumb: AssessmentQuestionBreadcrumb,
                element: (
                  <Suspense
                    fallback={<Loading />}
                    children={<AssessmentQuestionResolution />}
                  />
                ),
              },
            ],
          },
          {
            path: ":slug",
            breadcrumb: AssessmentBreadcrumb,
            element: (
              <Suspense
                fallback={<Loading />}
                children={<AssessmentDetail />}
              />
            ),
          },
        ],
      },
      {
        path: "exercises",
        children: [
          {
            index: true,
            breadcrumb: Message("breadcrumbs.exercises"),
            element: (
              <Suspense fallback={<Loading />} children={<ExerciseList />} />
            ),
          },
          {
            path: ":slug/results",
            breadcrumb: Message("breadcrumbs.exercises.results"),
            element: (
              <Suspense fallback={<Loading />} children={<ExerciseResult />} />
            ),
          },
          {
            path: ":slug",
            breadcrumb: ExerciseBreadcrumb,
            element: (
              <Suspense
                fallback={<Loading />}
                children={<ExerciseResolution />}
              />
            ),
          },
        ],
      },
    ],
  },
  {
    path: "*",
    element: <Suspense fallback={<Loading />} children={<NotFound />} />,
  },
];
