import React, { useContext, useEffect, useState } from "react";
import useSWR from "swr";
import { useApi } from "../hooks/useApi";

const ConfigContext = React.createContext();

export const ConfigProvider = ({ children }) => {
  const controllers = window.router.controllers;
  const controller = controllers.ConfigController;
  const { url } = controller.get();
  const apiClient = useApi();
  const requestConfig = {
    headers: {
      "X-Environment": process.env.NODE_ENV,
    },
  };
  
  const { data: config, error } = useSWR([url, requestConfig], apiClient);

  if (error) {
    throw error;
  }

  return (
    <ConfigContext.Provider value={config ?? {}}>
      {children}
    </ConfigContext.Provider>
  );
};

export const useFeatureFlags = () => {
  const ctx = useContext(ConfigContext);
  const [featureFlags, setFeatureFlags] = useState({});

  if (ctx == null) {
    throw new Error("ConfigConsumer must be used within a ConfigProvider");
  }

  useEffect(() => {
    // reduce feature flag list into a single key-value object
    setFeatureFlags(
      ctx.featureFlags.reduce((acc, { name, value }) => {
        acc[name] = value;
        return acc;
      }, {})
    );
  }, [ctx]);

  return featureFlags;
};
