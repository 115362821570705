import classNames from "classnames";
import { Breadcrumb } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { routes } from "../routes";

export function Breadcrumbs() {
  const breadcrumbs = useBreadcrumbs(routes);

  return (
    <StyledBreadcrumb>
      {breadcrumbs.map(({ match, breadcrumb }, index, { length }) => (
        <BreadcrumbItem
          key={match.pathname}
          to={match.pathname}
          active={index === length - 1}
        >
          {breadcrumb}
        </BreadcrumbItem>
      ))}
    </StyledBreadcrumb>
  );
}

const BreadcrumbItem = ({
  active,
  href,
  title,
  target,
  className,
  children,
  ...rest
}) => {
  // Don't try to render these props on non-active <span>.
  const linkProps = { href, title, target };

  return (
    <li className={classNames(className, { active: active })}>
      {active ? (
        <span {...rest}>{children}</span>
      ) : (
        <NavLink {...rest} {...linkProps}>{children}</NavLink>
      )}
    </li>
  );
};

const StyledBreadcrumb = styled(Breadcrumb)`
  font-size: 1.3rem;

  border-color: #cfdbe2;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
`;
