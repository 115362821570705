import { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useModalDialog } from ".";

export const ModalDialog = () => {
    const { state, dispatch } = useModalDialog();
    const { current, stack } = state;
    const [localCopy, setLocalCopy] = useState(current);
  
    useEffect(() => {
      return () => {
        dispatch({ type: "DESTROY" });
      };
    }, [dispatch]);
  
    useEffect(() => {
      if (stack.length > 0 && current !== localCopy) {
        dispatch({ type: "POP" });
        setLocalCopy(current);
      }
    }, [current, stack, dispatch, localCopy, setLocalCopy]);
  
    return current ? (
      <SweetAlert
        customClass="sweetalert-lg"
        {...current}
        onConfirm={() => {
          current.onConfirm?.();
          dispatch({ type: "POP" });
        }}
      ></SweetAlert>
    ) : null;
  };
  