import axios from "axios";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

export function useApi() {
  const navigate = useNavigate();

  return useCallback(
    (url, config) => {
      const { headers, ...rest } = config ?? {};
      const axiosInstance = axios.create();

      axiosInstance.interceptors.response.use(
        (res) => res,
        (err) => {
          if (err.response.status === 403) {
            // request was denied by the server, the process can't proceed.
            // start from the beginning
            navigate("/");
          }

          if (err.response.status === 401) {
            // request was denied by the server. Authentication is required.
            // redirect to login page
            navigate("/login", { replace: true });
          }

          // if (error?.status === 404) {
          //   // resource was not found on the server.
          //   // redirect to not found page
          //   browserHistory.push("/404");
          // }

          // Other kinds of errors simple reject the request
          return Promise.reject(err);
        }
      );

      return axiosInstance
        .request({
          url,
          timeout: 5000,
          headers: {
            ...headers,
            "Content-type": "application/json",
            Accept: "application/json",
          },
          withCredentials: true,
          ...rest,
        })
        .then((res) => res.data);
    },
    [navigate]
  );
}
