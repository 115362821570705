import { forwardRef } from "react";
import { Link, useLocation, useResolvedPath } from "react-router-dom";

/**
 * A <Link> wrapper that knows if it's "active" or not.
 */
export const NavLink = forwardRef(function NavLinkWithRef(
  {
    "aria-current": ariaCurrentProp = "page",
    caseSensitive = false,
    className: classNameProp = "",
    end = false,
    style: styleProp,
    to,
    children,
    wrapper,
    ...rest
  },
  ref
) {
  let location = useLocation();
  let path = useResolvedPath(to);
  let locationPathname = location.pathname;
  let toPathname = path.pathname;

  if (!caseSensitive) {
    locationPathname = locationPathname.toLowerCase();
    toPathname = toPathname.toLowerCase();
  }

  let isActive =
    locationPathname === toPathname ||
    (!end &&
      locationPathname.startsWith(toPathname) &&
      locationPathname.charAt(toPathname.length) === "/");
  let ariaCurrent = isActive ? ariaCurrentProp : undefined;
  let className;

  if (typeof classNameProp === "function") {
    className = classNameProp({ isActive });
  } else {
    // If the className prop is not a function, we use a default `active`
    // class for <NavLink />s that are active. In v5 `active` was the default
    // value for `activeClassName`, but we are removing that API and can still
    // use the old default behavior for a cleaner upgrade path and keep the
    // simple styling rules working as they currently do.
    className = [classNameProp, isActive ? "active" : null]
      .filter(Boolean)
      .join(" ");
  }

  let style =
    typeof styleProp === "function" ? styleProp({ isActive }) : styleProp;

  const emptyWrapper = ({ children }) => children;
  const Wrapper = wrapper ?? emptyWrapper;

  return (
    <Wrapper active={isActive}>
      <Link
        {...rest}
        {...{
          "aria-current": ariaCurrent,
          className,
          ref,
          style,
          to,
          children:
            typeof children === "function" ? children({ isActive }) : children,
        }}
      />
    </Wrapper>
  );
});

NavLink.displayName = "NavLink";
