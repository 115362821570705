import React from "react";
import { Breadcrumbs } from "./Breadcrumbs";

export const ContentWrapper = ({ children, style, unwrap, hideBreadcrumbs = false }) => {
  return (
    <main className="content-wrapper" style={style}>
      {!hideBreadcrumbs && <Breadcrumbs />}
      {unwrap ? <div className="unwrap">{children}</div> : children}
    </main>
  );
};
