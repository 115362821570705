import useSWR from "swr";
import { useApi } from "./useApi";

export function useAssessmentQuestion({ assessmentSlug, questionSlug }) {
  const controllers = window.router.controllers;
  const controller = controllers.AssessmentController;
  const apiClient = useApi();
  const { url } = controller.get(assessmentSlug);

  const { data: assessment, error } = useSWR(url, apiClient);
  const loading = !assessment && !error;

  const question = assessment?.questions.find(
    (question) => question.simulation.slug === questionSlug
  );

  return {
    assessment,
    question,
    loading,
    error,
  };
}
