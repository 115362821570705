import React, {Component} from 'react';
const pjson = require('../../package.json');

class Footer extends Component {

  render() {
    return (
      <footer>
        <span>&copy; {new Date().getFullYear()} - ({pjson.version}) - <a href="https://www.xlung.net">www.xlung.net</a></span>
      </footer>
    );
  }
}

export default Footer;
