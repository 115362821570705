import useSWR from "swr";
import { useApi } from "./useApi";

export function useExercise({ slug }) {
  const controllers = window.router.controllers;
  const controller = controllers.ExerciseController;

  const { url } = controller.get(slug);
  const apiClient = useApi();

  const { data: exercise, error } = useSWR(url, apiClient);
  const loading = !exercise && !error;

  return {
    exercise,
    loading,
    error,
  };
}
