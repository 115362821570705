import { useCallback } from "react";
import useSWR from "swr";
import { useApi } from "../../hooks/useApi";

export function useAssessment({ slug }) {
  const controllers = window.router.controllers;
  const controller = controllers.AssessmentController;
  const resolutionController = controllers.AssessmentResolutionController;
  const apiClient = useApi();
  const { url } = controller.get(slug);
  const { data: assessment, error } = useSWR(url, apiClient);
  const loading = !assessment && !error;

  const resolveQuestion = useCallback(
    (resolution) => {
      const { url } = resolutionController.addQuestionResult(slug);
      return apiClient(url, {
        method: "PUT",
        data: resolution,
      });
    },
    [slug, resolutionController, apiClient]
  );

  return {
    assessment,
    loading,
    error,
    resolveQuestion,
  };
}
