import { useCallback, useContext } from "react";
import { ModalContext } from "./ModalDialogProvider";

export const useModalDialog = () => {
  const ctx = useContext(ModalContext);
  if (ctx == null) {
    throw new Error(
      "ModalDialogConsumer must be used within a ModalDialogProvider"
    );
  }
  const { state, dispatch } = ctx;

  const pushDialog = useCallback(
    
    (dialog) => {
      dispatch({ type: "PUSH", dialog });
    },
    [dispatch]
  );

  return {
    pushDialog,
    state,
    dispatch,
  };
};
