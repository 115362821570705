import { createContext, useMemo, useReducer } from "react";
import produce from "immer";

function stackReducer(state, action) {
  switch (action.type) {
    case "PUSH":
      return produce(state, (state) => {
        if (state.stack.length === 0) {
          state.current = action.dialog;
        }

        state.stack.push(action.dialog);
      });
    case "POP":
      return produce(state, (state) => {
        state.current = state.stack.pop();
      });
    case "DESTROY":
      return {
        stack: [],
        current: null,
      };
    default:
      return state;
  }
}

export const ModalContext = createContext();

export const ModalDialogProvider = ({ children }) => {
  const [state, dispatch] = useReducer(stackReducer, {
    stack: [],
    current: null,
  });

  const value = useMemo(() => ({ state, dispatch }), [state, dispatch]);
  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
};
