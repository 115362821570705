import React from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import Footer from "./Footer";
import HeaderHorizontal from "./HeaderHorizontal";
import { NavLink, Outlet, Routes } from "react-router-dom";

export const AppLayout = () => {
  /**
   * Animations supported:
   *
   * 'rag-fadeIn'
   * 'rag-fadeInUp'
   * 'rag-fadeInDown'
   * 'rag-fadeInRight'
   * 'rag-fadeInLeft'
   * 'rag-fadeInUpBig'
   * 'rag-fadeInDownBig'
   * 'rag-fadeInRightBig'
   * 'rag-fadeInLeftBig'
   * 'rag-zoomBackDown'
   */

  const animationName = "rag-fadeIn";

  return (
    <div className="wrapper">
      <HeaderHorizontal />
      <ReactCSSTransitionGroup
        component="section"
        transitionName={animationName}
        transitionEnterTimeout={500}
        transitionLeaveTimeout={500}
      >
        {React.cloneElement(<Outlet />, {
          key: Math.random(),
        })}
      </ReactCSSTransitionGroup>
      <Footer />
    </div>
  );
};
