import { useContext } from "react";
import { AuthContext } from "./AuthProvider";

export const useAuth = () => {
  const ctx = useContext(AuthContext);

  if (ctx == null) {
    throw new Error("AuthConsumer must be used within a AuthProvider");
  }

  return ctx;
};
